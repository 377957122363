/** @format */

import React from "react";

function Publish() {
  return (
    <div className='container-fluid publish-bg'>
      <div className='container p-5'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <img src='assets/shuttle.svg' alt='' />
            <h1 className='publish-heading' data-aos='fade-right'>
              Publish your Game with <br className='line-break' /> Relymer
              Studio
            </h1>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-1 mx-2'></div>
          <div className='col-md-3 col-12'>
            <div className='card text-center publish-card' data-aos='fade-up'>
              <div className='card-body'>
                <img src='assets/icon.svg' alt='' />
                <h1>An Entire team for you</h1>
                <p>
                  Our team of professionals will help you during each stage of
                  game development, through ideation, Game economics and level
                  modelling, We Got You!
                </p>
              </div>
            </div>
          </div>

          <div className='col-md-3'>
            <div
              className='card text-center publish-card'
              data-aos='fade-right'>
              <div className='card-body'>
                <img src='assets/icon-1.svg' alt='' />
                <h1>Top Mobile game developer and Publisher</h1>
                <p>
                  Collaborate with industry leaders, mentors, and specialists in
                  mobile gaming. We'll take your game to the top of the charts
                  together.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card text-center publish-card' data-aos='fade-up'>
              <div className='card-body'>
                <img src='assets/icon-2.svg' alt='' />
                <h1>All around support for your Game</h1>
                <p>
                  A Game Product team comprised of UA, monetization, and
                  analytics professionals that ensure that your #1 hit game
                  remains optimised, lucrative, and scalable over time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Publish;
