/** @format */

import React from "react";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
function Footer() {
  return (
    <>
      <div className='container-fluid footer-bg'>
        <div className='container p-5'>
          <div className='row'>
            <div className='col-md-5'>
              <img src='assets/footer.svg' alt='' />
            </div>

            <div className='col-md-3 d-flex footer-img-content'>
              <div>
                <img src='assets/contact.svg' alt='' />
              </div>
              <div className='footer-content'>
                <p className='mb-0'>+92 333 7359637</p>
                <a href='mailto:info@relymer.com'>info@relymer.com</a>
              </div>
            </div>

            <div className='col-md-4 d-flex footer-img-content'>
              <div>
                <img src='assets/position.svg' alt='' />
              </div>
              <div className='footer-content'>
                <p>
                  First Floor, Ace Uptown, Plot # 22-A, North Road, E 11/2
                  Islamabad
                </p>
              </div>
            </div>
            <hr className='mt-4' style={{ color: "#fff" }} />
          </div>

          <div className='row'>
            <div className='col-md-5 footer-content'>
              <h1>About us</h1>
              <p>
              We can help you design games across multiple gaming genres in single and massive-multiplayer modes.
              </p>
             {/* <a href="https://www.facebook.com/relymer" target="_blank" rel="noopener noreferrer"> <FaFacebook /></a> */}
             <a href="https://www.instagram.com/relymerstudio/" target="_blank" rel="noopener noreferrer">  <FaInstagramSquare /></a>
             <a href="https://www.linkedin.com/company/relymer-studio" target="_blank" rel="noopener noreferrer">   <FaLinkedin /></a>
             <a href="https://twitter.com/RelymerStudio" target="_blank" rel="noopener noreferrer">  <FaTwitter /></a>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 footer-content col-6 '>
              <h1>Our links</h1>
              <ul className='list-unstyled'>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/'>About us</a>
                </li>
                <li>
                  <a href='/'>Career</a>
                </li>
                <li>
                  <a href='/'>Contact</a>
                </li>
              </ul>
            </div>

            <div className='col-md-3 footer-content col-6'>
              <h1>Our Services</h1>
              <ul className='list-unstyled'>
                <li>
                  <a href='/'>Publishing</a>
                </li>
                <li>
                  <a href='/'>Game</a>
                </li>
                <li>
                  <a href='/'>CCPA Request</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ background: "#FF6262" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center pt-1 footer-bottom'>
              <p className='mt-1'>
                Copyright © 2022{" "}
                <a
                  href='https://relymer.com'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <strong style={{ color: "#fff" }}>Relymer Group.</strong>
                </a>{" "}
                All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
