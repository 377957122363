/** @format */

import React from "react";
import { ImArrowUpRight2 } from "react-icons/im";
function Hero() {
  return (
    <div
      className='container-fluid hero-bg pt-5 '
      style={{ marginTop: "-103px" }}>
      <div className='container pt-5 mt-5 '>
        <div className='row'>
          <div className='col-md-7 pt-5'>
            <h1 data-aos='fade-down'>
              Bring your Game <br className='line-break' />
              fantasies to Life here!!
            </h1>
            <p data-aos='fade-up'>Game design and development studio</p>
            <button className='btn' data-aos='zoom-in-up'>
              <span>
                {" "}
                Apply now <ImArrowUpRight2 />
              </span>{" "}
            </button>
            {/* <img src="assets/Assetss.png" alt="" width="50" /> */}
          </div>
          <div className='col-md-5 text-center'>
            <div className='video-bg video-res' data-aos='zoom-in-up'>
              <video
                height='600px'
                controls={false}
                autoPlay
                muted
                loop
                playsInline>
                <source
                  src={
                    process.env.REACT_APP_S3_URL +
                    "/In9Qz8R.mp4"
                  }
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
