import React from "react";
import {ImArrowUpRight2} from 'react-icons/im'

function HyperCasual() {
  return (
    <div className="container mt-5 pt-5 mb-5" id="games">
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="assets/Frame.svg" alt="" />
          <h1 className="heading">Hyper-Casual</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1 mx-4"></div>
        <div className="col-md-3 col-12">
        <div className="wrapper" data-aos ='fade-down'>
          <div className="card">
            <img src={"/assets/img-copy.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-up'>
          <div className="card">
            <img src={"/assets/img-1.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>

        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-right'>
          <div className="card">
            <img src={"/assets/img-2.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-1 mx-4"></div>
        <div className="col-md-3 col-12">
        <div className="wrapper" data-aos ='fade-down'>
          <div className="card">
            <img src={"/assets/img-1.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-up'>
          <div className="card">
            <img src={"/assets/img-copy.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>

        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-right'>
          <div className="card">
            <img src={"/assets/img-3.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-12">
          <h1 className="heading">Casual</h1>
        </div>
        <div className="col-md-1 mx-4"></div>
        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-down'>
          <div className="card">
            <img src={"/assets/img-2.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="wrapper" data-aos ='fade-right'>
          <div className="card">
            <img src={"/assets/img-copy.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="wrapper" data-aos ='fade-up'>
          <div className="card">
            <img src={"/assets/img-1.svg"} alt="" className="top-img"/>
            <div className="info text-center">
              <h1>The President</h1>
              <p>
                The boss arrived in the office
              </p>
              <img src="assets/App.svg" alt="" className="w-50"/> <br />
                <img src="assets/Google.svg" alt="" className="w-50 mt-3"/>
            </div>
          </div>
          </div>
        </div>
      </div>
      <button className=" d-block mt-4 btn-hover color-1 res-btn" data-aos ='zoom-in-up'>
        More Game <ImArrowUpRight2 />
      </button>
    </div>
  );
}

export default HyperCasual;
