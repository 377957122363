import React from "react";

const Loading = () => {
  return (
    <>
      <div className="container-fluid loader" >
        <div className='face'>
          <div className='container'>
            <img
              src={"assets/Assetss.png"} 
              width='200' alt=""
            />
            <span className='loading' />
            <div className='caption'>
              <h2>Relymer Studio</h2>
              <ul>
                <li>L</li> 
                <li>o</li>
                <li>a</li>
                <li>d</li>
                <li>i</li>
                <li>n</li>
                <li>g</li>
            
             
               


              </ul>
            </div>
          </div>
        </div>
       
      </div>    
    </>
  );
};

export default Loading;