/** @format */

import React from "react";

function NewSection() {
  return (
    <div className='container-fluid new-sec-bg pb-5' id='services'>
      <div className='container pt-5 pb-5'>
        {/* <div className="row">
          <div className="col-md-12">
            <div class="accordian">
              <ul>
                <li>
                  <div class="image_title">
                    <a href="#">Celebrity</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/3yiC6Yq.jpg" />
                  </a>
                </li>
                <li>
                  <div class="image_title">
                    <a href="#">Toy Story 2</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/40Ly3VB.jpg" />
                  </a>
                </li>
                <li>
                  <div class="image_title">
                    <a href="#">Wall-E</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/00kih8g.jpg" />
                  </a>
                </li>
                <li>
                  <div class="image_title">
                    <a href="#">Up</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/2rT2vdx.jpg" />
                  </a>
                </li>
                <li>
                  <div class="image_title">
                    <a href="#">Cars 2</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/8k3N3EL.jpg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* 
        <div className="row mt-5">
          <div className="col-md-6">
            <div class="accordian">
              <ul>
                <li>
                  <div class="image_title">
                    <a href="#">Celebrity</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/3yiC6Yq.jpg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div class="accordian">
              <ul>
                <li>
                  <div class="image_title">
                    <a href="#">Celebrity</a>
                  </div>
                  <a href="#">
                    <img src="http://thecodeplayer.com/uploads/media/3yiC6Yq.jpg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <div className='row'>
          <h1 className='text-center heading new-sec-h1'>
            Game Development services <br /> and art solution
          </h1>
          <p className='text-center new-sec-p m-auto'>
            Our game development studio does more than just make games. We
            create plots for them by utilising fruitful game art solutions.
            Unique ideas, sappy animations, and vivid characters define our
            projects and bring our client’s vision into reality.
          </p>
          <div className='col-md-12 newsection'>
            <section className='cards'>
              <div className='card1 istcard'>
                <img src='assets/3.webp' alt='' />
                <h1>
                  Creating 2D Art Consists Of Creating Character Design, <br />{" "}
                  Environment Design, Concept, And Vector Art.
                </h1>
                <h2>2D ART</h2>
              </div>

              <div className='card2 ndcard'>
                <img src='assets/2.webp' alt='' className='w-50' />
                <h2>UNITY GAME DEVELOPMENT</h2>
                <h1>
                  Our Game Development Team Uses The Unity Platform To Build A
                  Winning And Interactive <br /> 2D, 3D Games With A Flawless{" "}
                  <br />
                  Performance On Mobile, <br /> Web, PC, Or Console.
                </h1>
              </div>
            </section>
            <section className='cards'>
              <div className='card2 ndcard'>
                <img
                  className='w-50'
                  src='assets/4.webp'
                  alt=''
                  // style={{ position: "absolute", top: "0", bottom: "0" , left:'0'}}
                />
                <h2>ANIMATION</h2>
                <h1>
                  Preparing Animations Is A Method Of Generating Several Snaps
                  And Showing Them In A Speedy Sequence, Which Is Making The
                  Illusion Of Movement.
                </h1>
              </div>
              <div className='card1 istcard'>
                <img src='assets/1.webp' alt='' />
                <h1>
                  The Main Goal Of 3D Modeling Is To Make A Visual Volumetric{" "}
                  <br />
                  Image Of The Desired Object, Which Will Correspond To The{" "}
                  <br />
                  Entities From The Real World.
                </h1>
                <h2>3D ART</h2>
              </div>
            </section>

            <section className='cards'>
              <div className='card1 istcard'>
                <img
                  src='assets/r6.png'
                  alt=''
                  className='w-50'
                  style={{
                    position: "absolute",
                    bottom: "0",
                    width: "40%",
                    left: "0",
                  }}
                />
                <h1>
                  Every Project Is Unique And Deserves To <br /> Have Its
                  Graphic Style That Conveys A <br />
                  Vision And Set The Tone For The Whole Game.
                </h1>
                <h2>CONCEPT ART</h2>
              </div>
              <div className='card2 ndcard'>
                <img
                  src='assets/5.webp'
                  alt=''
                  style={{ position: "absolute" }}
                  className='w-50'
                />
                <h2>MOBILE DEVELOPMENT</h2>
                <h1>
                  Preparing Animations Is A Method Of Generating Several Snaps
                  And Showing Them In A Speedy Sequence, Which Is Making The
                  Illusion Of Movement.
                </h1>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSection;
