/** @format */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layouts/Base";
import Home from "./pages/Home";
const Router = () => {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
        </Routes>
      </Base>
    </BrowserRouter>
  ); 
};

export default Router;
