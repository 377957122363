import React from 'react'
import {ImArrowUpRight2} from 'react-icons/im'
function Dashboard() {
  return (
    <div className="container dashboard mt-5 mb-5 pt-5 pb-5">
        <div className="row">
            <div className="col-md-5 m-auto" data-aos ='fade-down'>
                <h1 className='heading text-start'>The CLIK Dashboard</h1>
                <p>NEW! Download the CLIK mobile app</p>
                
                <ul className='list-unstyled'>
                    <li>A free self-serve testing platform for your games</li>
                    <li>Full control & data transparency in your testing & publishing process</li>
                    <li>Access to all the relevant publishing data in every single step</li>
                    <li>Ongoing feedback from our hyper-casual experts</li>
                </ul>
                <button className='center-button btn-hover color-1 res-btn'>Test Your Game <ImArrowUpRight2/></button>
            </div>
            <div className="col-md-7" data-aos ='fade-right'>
                <img src={"/assets/Frame 1.svg"} alt=""  className='res-img mt-5'/>
            </div>
        </div>
    </div>
  )
}

export default Dashboard