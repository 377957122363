/** @format */

import React from "react";

function Section1() {
  return (
    <div className='container sec1 pt-0 mt-5 ' id='about'>
      <div className='row'>
        <div className='col-lg-12 pb-5 text-center'>
          <img src='assets/logo2.svg' alt='' className='res-img' />
        </div>
        <div className='col-md-12 text-center sec1-content'>
          <img src='assets/Group.svg' alt='' className='res-img' />
          <h3>We Are</h3>
          <h1 data-aos='fade-down' data-aos-anchor-placement='center-center'>
            Relymer Studio
          </h1>
          <img
            src='assets/Line.svg'
            alt=''
            className='res-img'
            data-aos='fade-up'
            data-aos-anchor-placement='center-center'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-sm-2 '>
          <div className='card sec1-cards' data-aos='fade-down'>
            <div className='card-body'>
              <img src='assets/Star.svg' alt='' />
              <p>#5</p>
              <h3>Top Publisher</h3>
            </div>
          </div>
        </div>
        <div className='col-sm-2 '>
          <div className='card sec1-cards' data-aos='fade-up'>
            <div className='card-body'>
              <img src='assets/Star.svg' alt='' />
              <p>5B</p>
              <h3>Download</h3>
            </div>
          </div>
        </div>
        <div className='col-sm-2 '>
          <div className='card sec1-cards' data-aos='fade-down'>
            <div className='card-body'>
              <img src='assets/Star.svg' alt='' />
              <p>234M</p>
              <h3>MAU</h3>
            </div>
          </div>
        </div>
        <div className='col-md-3'></div>
      </div>

      <div className='row'>
        <div className='col-md-12 text-center res-para'>
          <p className='w-75 m-auto mt-5 mb-b pb-5' data-aos='fade-right'>
            We are a top tier team of game enthusiasts, who have a passion and
            keen eye for playing top games and designing them. We can help you
            design games across multiple gaming genres in single and
            massive-multiplayer modes.
          </p>
          <h1 data-aos='fade-up'>
            We focus on 2 highly popular lines <br className='line-break' /> of
            mobile games
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Section1;
