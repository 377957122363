import React from 'react'

function Banner() {
  return (
    <>
    
    <div className="container-fluid banner-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className='d-flex align-items-center justify-content-center mt-5 res-banner' data-aos="zoom-out"
     >
                    <h1>Join Relymer Team</h1>
                    <button>Join now</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    

</>
  
  )
}

export default Banner