import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import "./App.scss";
import AOS from "aos";
import 'aos/dist/aos.css'

import React, { useState, useEffect } from "react";
import Router from './Router';
import Loading from './components/utils/Loader';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    AOS.init({duration:'1500'})
    
  })
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  return (
    <>
   {loading ? <Loading /> : <Router />}
    </>
  );
}

export default App;
