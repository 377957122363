/** @format */

import React from "react";
import Banner from "../components/elements/Banner";
import Dashboard from "../components/elements/Dashboard";
import Hero from "../components/elements/Hero";
import HyperCasual from "../components/elements/HyperCasual";
import Publish from "../components/elements/Publish";
import Section1 from "../components/elements/Section1";
import Success from "../components/elements/Success";
import NewSection from "../components/elements/NewSection";
function Home() {
  return (
    <>
      <Hero />
      <Section1 />
      <HyperCasual />
      <Publish />
      <Dashboard />
      <NewSection />
      <Success />
      <Banner />
    </>
  );
}

export default Home;
