/** @format */

import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
// import { ImQuotesLeft } from "react-icons/im";
import { BsCloudDownload, BsCameraVideo } from "react-icons/bs";
function Success() {
  return (
    <div className='container mt-5 pt-5 mb-5'>
      <div className='row'>
        <div className='col-md-12 text-center mt-4'>
          <img src='assets/cup.svg' alt='' data-aos='fade-down' />
          <h1 className='success-heading' data-aos='fade-right'>
            Success Stories
          </h1>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-6'>
          <div
            className='card success-card'
            data-aos='fade-up'
           >
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <img src='assets/1.svg' alt='' />
                  <div className='d-flex inner-div'>
                    <FaRegStar />
                    <p>#1 In 10 Countries</p>
                  </div>
                  <div className='d-flex inner-div inner-div1'>
                    <BsCloudDownload />
                    <p>#1 In 10 Countries</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <h1>DIY Makeup</h1>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p>5 Star Rating</p>
                  {/* <ImQuotesLeft /> */}
                  <img src='assets/quot.svg' alt='' />
                  <p className='quote'>
                    “Relymer Studio invested in our relationship long before DIY
                    Makeup came to life!“
                  </p>
                  <button>
                    <BsCameraVideo size={23} /> VIDEO INSIDE
                  </button>
                  <h3>Jeppe Kjaersgaard Nielsen</h3>
                  <h6>COO, Cameo Games</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div
            className='card success-card'
            data-aos='fade-down'
            >
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <img src='assets/2.svg' alt='' />
                  <div className='d-flex inner-div'>
                    <FaRegStar />
                    <p>#1 In 10 Countries</p>
                  </div>
                  <div className='d-flex inner-div inner-div1'>
                    <BsCloudDownload />
                    <p>#1 In 10 Countries</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <h1>DIY Makeup</h1>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p>5 Star Rating</p>
                  {/* <ImQuotesLeft /> */}
                  <img src='assets/quot.svg' alt='' />
                  <p className='quote'>
                    “Relymer Studio invested in our relationship long before DIY
                    Makeup came to life!“
                  </p>
                  <button>
                    <BsCameraVideo size={23} /> VIDEO INSIDE
                  </button>
                  <h3>Jeppe Kjaersgaard Nielsen</h3>
                  <h6>COO, Cameo Games</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-md-6'>
          <div
            className='card success-card'
            data-aos='fade-up'
            >
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <img
                    src={"/assets/3.svg"}
                    alt=''
                  />
                  <div className='d-flex inner-div'>
                    <FaRegStar />
                    <p>#1 In 10 Countries</p>
                  </div>
                  <div className='d-flex inner-div inner-div1'>
                    <BsCloudDownload />
                    <p>#1 In 10 Countries</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <h1>DIY Makeup</h1>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p>5 Star Rating</p>
                  {/* <ImQuotesLeft /> */}
                  <img src='assets/quot.svg' alt='' />
                  <p className='quote'>
                    “Relymer Studio invested in our relationship long before DIY
                    Makeup came to life!“
                  </p>
                  <button>
                    <BsCameraVideo size={23} /> VIDEO INSIDE
                  </button>
                  <h3>Jeppe Kjaersgaard Nielsen</h3>
                  <h6>COO, Cameo Games</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div
            className='card success-card'
            data-aos='fade-right'
         >
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <img src='assets/4.svg' alt='' />
                  <div className='d-flex inner-div'>
                    <FaRegStar />
                    <p>#1 In 10 Countries</p>
                  </div>
                  <div className='d-flex inner-div inner-div1'>
                    <BsCloudDownload />
                    <p>#1 In 10 Countries</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <h1>DIY Makeup</h1>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p>5 Star Rating</p>
                  {/* <ImQuotesLeft /> */}
                  <img src='assets/quot.svg' alt='' />
                  <p className='quote'>
                    “Relymer Studio invested in our relationship long before DIY
                    Makeup came to life!“
                  </p>
                  <button>
                    <BsCameraVideo size={23} /> VIDEO INSIDE
                  </button>
                  <h3>Jeppe Kjaersgaard Nielsen</h3>
                  <h6>COO, Cameo Games</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className='w-25 text-center m-auto mt-5 btn-hover color-1 res-btn'
          data-aos='zoom-in'
          data-aos-anchor-placement='center-center'>
          Load More Success Story
        </button>
      </div>
    </div>
  );
}

export default Success;
